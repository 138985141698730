import { useCallback, useState } from 'react';

import { type Media } from '@lp-lib/game';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { type MessageTemplate } from '../../types/messageTemplate';
import { MediaUtils } from '../../utils/media';
import { CopyTooltipIcon } from '../icons/CopyTooltipIcon';
import { Loading } from '../Loading';
import { useMessageTemplates } from './hooks';
import { type MessageVariableMapping, type TranslatedMessage } from './types';
import { fillMessageWithVars, slackMessageToHTML } from './utils';

// The code is borrowed from https://stackoverflow.com/a/50067769,
// It's slightly not idea since it uses a deprecated function,
// since it's just an internal tool, should be fine.
function copy(content: string) {
  function listener(e: ClipboardEvent) {
    e.clipboardData?.setData('text/html', content);
    e.clipboardData?.setData('text/plain', content);
    e.preventDefault();
  }
  document.addEventListener('copy', listener);
  document.execCommand('copy');
  document.removeEventListener('copy', listener);
}

function OpenMediaButton(props: {
  media: Media | null;
  mediaTemplateId: string | null;
  roundId?: string;
  pairingId?: string;
}): JSX.Element | null {
  const { media, mediaTemplateId, roundId, pairingId } = props;
  const {
    state: { transformed: callState },
    call,
  } = useAsyncCall(
    useCallback(async () => {
      if (!mediaTemplateId || (!roundId && !pairingId)) {
        return null;
      }

      const resp = await apiService.dmg.renderTemplate({
        templateId: mediaTemplateId,
        pairingId,
        roundId,
      });
      return resp.data.url;
    }, [mediaTemplateId, roundId, pairingId])
  );

  const handleClick = async () => {
    const mediaUrl = MediaUtils.PickMediaUrl(media);
    if (mediaUrl) {
      window.open(mediaUrl, '_blank');
    } else if (mediaTemplateId) {
      const maybeUrl = await call();
      if (maybeUrl) {
        window.open(maybeUrl, '_blank');
      }
    }
  };

  if (!media && !mediaTemplateId) return null;

  return (
    <button
      className='disabled:opacity-50 flex items-center gap-1'
      onClick={handleClick}
      disabled={callState.isRunning}
    >
      <div className='btn text-primary text-2xs underline'>Open Media</div>
      {callState.isRunning && <Loading text='' imgClassName='w-4 h-4' />}
    </button>
  );
}

function Translated(props: {
  template: MessageTemplate;
  vars: MessageVariableMapping;
  roundId?: string;
  pairingId?: string;
}): JSX.Element {
  const { template, vars, roundId, pairingId } = props;
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  const onCopy = () => {
    copy(
      template.withPlainText
        ? fillMessageWithVars(template.body, vars)
        : slackMessageToHTML(template.body, vars)
    );
    setShowCopyTooltip(true);
    setTimeout(() => {
      setShowCopyTooltip(false);
    }, 3000);
  };

  return (
    <div className='flex items-center'>
      <div className='text-2xs text-white'>{template.title}</div>
      <div className='flex gap-2 ml-2 relative'>
        <button
          className='btn text-primary text-2xs underline'
          onClick={onCopy}
        >
          Copy
        </button>
        <CopyTooltipIcon
          className={`absolute left-1/2 transform -translate-x-1/2 top-4 ${
            showCopyTooltip ? 'visible' : 'invisible'
          }`}
          text='Copied'
        />
        <OpenMediaButton
          media={template.media}
          mediaTemplateId={template.mediaTemplateId}
          roundId={roundId}
          pairingId={pairingId}
        />
      </div>
    </div>
  );
}

export function MessageTemplateTranslator({
  vars,
  includePredefined = true,
  roundId,
  pairingId,
}: {
  vars: MessageVariableMapping;
  includePredefined?: boolean;
  roundId?: string;
  pairingId?: string;
}): JSX.Element {
  const { templates, isLoading, error } = useMessageTemplates({
    includePredefined,
  });

  if (isLoading) return <Loading />;
  if (!!error)
    return <div className='text-2xs text-red-002'>err2s(loadError)</div>;

  return (
    <div className='flex flex-col gap-3'>
      {templates?.map((t) => (
        <Translated
          key={t.id}
          template={t}
          vars={vars}
          pairingId={pairingId}
          roundId={roundId}
        />
      ))}
    </div>
  );
}

export function useTranslateMessageTemplates(): (
  vars: MessageVariableMapping
) => TranslatedMessage[] | undefined {
  const { templates } = useMessageTemplates({
    includePredefined: true,
  });

  return useCallback(
    (vars: MessageVariableMapping) => {
      return templates?.map(
        (template) =>
          ({
            title: template.title,
            body: template.withPlainText
              ? fillMessageWithVars(template.body, vars)
              : slackMessageToHTML(template.body, vars),
          } as TranslatedMessage)
      );
    },
    [templates]
  );
}
